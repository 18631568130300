import useSWR from 'swr'

const SUBDOMAIN = process.env.GATSBY_SHOPIFY_SUBDOMAIN
const ACCESS_TOKEN = process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN

const fetcher = async query => {
  const data = await fetch(
    `https://${SUBDOMAIN}.myshopify.com/api/2021-01/graphql.json`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        'X-Shopify-Storefront-Access-Token': ACCESS_TOKEN,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query })
    }
  )

  return await data.json()
}

export default function useShopify(query) {
  // see https://swr.vercel.app/docs/options#options for more options!
  const { data, error } = useSWR(query, fetcher)

  return {
    data: data?.data,
    isLoading: !error && !data,
    error: error
  }
}
